$wrapper-min-width: 1280px;
$wrapper-max-width: 1600px;

$board-max-width : 1360px;

$header-height   : 96px; //60px; + 36px;
$m-header-height : 48px;
$navigation-width: 260px;

$content-padding  : 20px;
$page-padding     : 40px;
$m-content-padding: 16px;
$m-page-padding   : 16px;

$default-border-radius: 10px;