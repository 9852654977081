.navigation {
  display       : flex;
  flex-direction: column;

  ul {
    display       : flex;
    flex-direction: column;

    li {
      height     : 60px;
      display    : flex;
      align-items: flex-start;

      &.on {
        span {
          font-weight: bold;
          color      : $text-color;
        }
      }

      span {
        cursor   : pointer;
        font-size: 20px;
        color    : #777777;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .navigation {
    width        : 100%;
    border-bottom: solid 1px rgb(134, 140, 159);

    ul {
      padding   : 0px 16px;
      box-sizing: border-box;

      flex-direction: row;
      width         : 100%;
      overflow-y    : auto;
      height        : 48px;

      li {
        display        : flex;
        justify-content: center;
        align-items    : center;
        height         : 100%;
        margin-right   : 48px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          white-space: nowrap;
          font-size  : 18px;
        }
      }

    }
  }
}