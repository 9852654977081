.basic-button {
  display        : flex;
  height         : 48px;
  width          : 100%;
  background     : $primary-color;
  display        : flex;
  justify-content: center;
  align-items    : center;
  border-radius  : 24px;
  box-sizing     : border-box;
  padding        : 0px 24px;

  &.cancle {
    background-color: $placeholder-color;

    &:hover {
      background-color: $placeholder-color;
    }
  }

  &.delete {
    background-color: $error-color;

    &:hover {
      background: $error-color;
    }
  }

  &:hover {
    background: $primary-hover-color;


  }

  &:disabled {
    opacity: 0.5;
  }

  span {
    color      : #fff;
    font-size  : 18px;
    font-weight: bold;
    white-space: nowrap;

  }

  &.line {
    background: transparent;
    border    : solid 1px $primary-color;

    span {
      color: $primary-color;

    }
  }

  &.header {
    height : 32px;
    padding: 0px 24px;

    span {
      font-size  : 16px;
      font-weight: normal;
    }
  }

  &.line {
    background: transparent;
    border    : solid 1px $primary-color;

    span {
      color: $primary-color;
    }
  }
}