.main-footer {
  display        : flex;
  justify-content: space-between;
  width          : 100%;
  max-width      : $wrapper-max-width;
  padding        : 0px $page-padding;
  box-sizing     : border-box;


  .copyright__box {
    display       : flex;
    flex-direction: column;

    b {
      color        : #777;
      margin-bottom: 18px;
    }

    .desc__box {
      margin-top    : 8px;
      display       : flex;
      flex-direction: column;

      p {
        margin-bottom: 2px;
        color        : #999;
        font-size    : 12px;
      }
    }
  }

  .menu__box {
    display: flex;

    ul {
      display: flex;

      li {
        margin-right: 24px;

        span {
          cursor: pointer;
          color : #777;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

}

@media only screen and (min-width: 1025) and (max-width: 1440px) {}

@media only screen and (min-width: 766px) and (max-width: 1024px) {}

@media only screen and (max-width: 1280px) {
  .main-footer {
    display       : flex;
    flex-direction: column-reverse;
    align-items   : flex-start;
    padding       : 0px 16px;

    .copyright__box {
      margin-top    : 24px;
      flex-direction: column;
      align-items   : center;

      b {
        font-size : 14px;
        text-align: center;
      }

      .desc__box {
        p {
          font-size: 12px;
        }
      }
    }

    .menu__box {
      width: 100%;

      ul {

        width: 100%;

        li {
          flex           : 1;
          display        : flex;
          justify-content: center;
          margin-right   : none;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}