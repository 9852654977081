.home-page {
  display       : flex;
  flex-direction: column;

  .naver-cafe__container {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-top    : 160px;
    margin-bottom : 160px;

    .caption__box {
      display       : flex;
      flex-direction: column;
      flex          : 1;
      align-items   : center;

      h2 {
        font-style    : normal;
        font-weight   : 700;
        font-size     : 48px;
        line-height   : 64px;
        letter-spacing: -2px;
        color         : #000000;
      }

      h4 {
        margin-top    : 24px;
        font-style    : normal;
        font-weight   : 300;
        font-size     : 18px;
        line-height   : 28px;
        text-align    : center;
        letter-spacing: -1px;
        color         : #000000;
        white-space   : pre-line;

      }
    }

    .img__box {
      margin-top       : 64px;
      display          : flex;
      background       : grey;
      width            : 640px;
      height           : 480px;
      align-items      : center;
      justify-content  : center;
      background       : url("../images/naver_cafe_mask.png");
      width            : 944px;
      height           : 636.65px;
      background-size  : 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .benefit__container {
    display       : flex;
    flex-direction: column;
    padding-top   : 160px;
    padding-bottom: 160px;

    &>header {
      width          : 100%;
      display        : flex;
      justify-content: center;
      margin-bottom  : 60px;

      h1 {
        font-style : normal;
        font-weight: 700;
        font-size  : 48px;
        line-height: 64px;
        /* identical to box height, or 133% */

        letter-spacing: -2px;

        color: #FFFFFF;
      }
    }

    ul {
      display              : grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap             : 50px;

      li {
        display       : flex;
        flex-direction: column;
        background    : #FFFFFF;
        box-shadow    : 2px 8px 24px rgba(0, 0, 0, 0.08);
        border-radius : 32px;
        padding       : 32px;
        box-sizing    : border-box;

        h2 {
          font-style    : normal;
          font-weight   : 700;
          font-size     : 18px;
          line-height   : 32px;
          letter-spacing: -1px;
          color         : #000000;
          margin-bottom : 8px;

          b {
            color         : #FF0000;
            font-style    : normal;
            font-weight   : 700;
            font-size     : 20px;
            line-height   : 32px;
            letter-spacing: -1px;
          }
        }

        p {
          font-style : normal;
          font-weight: 300;
          font-size  : 16px;
          line-height: 24px;
          /* or 150% */

          letter-spacing: -1px;

          color: #000000;
        }
      }
    }
  }

  .bg__item {
    width                : 100%;
    height               : 1000px;
    background           : url("../images/beach.png");
    background-size      : cover;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat    : no-repeat;
    position             : absolute;
    top                  : 0;
    left                 : 0;

  }

  .ment__container {
    display       : flex;
    flex-direction: column;
    height        : 1000px;
    align-items   : center;

    .caption__box {
      display        : flex;
      flex-direction : column;
      align-items    : center;
      justify-content: center;
      height         : 100%;

      h3 {
        font-style    : normal;
        font-weight   : 300;
        font-size     : 20px;
        line-height   : 32px;
        text-align    : center;
        letter-spacing: -1px;
        color         : #FFFFFF;
        white-space   : pre-line;

      }

      h1 {
        margin-top    : 24px;
        font-style    : normal;
        font-weight   : 500;
        font-size     : 32px;
        line-height   : 48px;
        text-align    : center;
        letter-spacing: -1px;
        color         : #FFFFFF;
      }
    }


  }

  .how-to-use__container {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-top    : 160px;

    &>header {
      display        : flex;
      justify-content: center;

      h1 {
        font-style    : normal;
        font-weight   : 700;
        font-size     : 48px;
        line-height   : 64px;
        letter-spacing: -2px;
        color         : #000000;
      }
    }


    .guide__box {
      margin-top     : 24px;
      display        : flex;
      width          : 100%;
      justify-content: center;

      ul {
        width          : 100%;
        display        : flex;
        justify-content: center;
        flex-wrap      : wrap;
        max-width      : 960px;

        li {
          width          : fit-content;
          padding        : 0px 24px;
          margin-right   : 8px;
          background     : #F4F4F4;
          border-radius  : 100px;
          height         : 40px;
          margin-bottom  : 12px;
          display        : flex;
          justify-content: center;
          align-items    : center;
          cursor         : pointer;

          &.on {
            background: $primary-color;

            span {
              color: #fff;
            }
          }

          span {

            font-style    : normal;
            font-weight   : 300;
            font-size     : 14px;
            line-height   : 14px;
            text-align    : center;
            letter-spacing: -1px;
          }
        }
      }
    }

    .youtube__box {
      margin-top     : 64px;
      display        : flex;
      align-items    : center;
      justify-content: center;
      width          : 100%;
      max-width      : 1280px;
      box-shadow     : rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;

      .ratio__box {
        width         : 100%;
        padding-bottom: 56.25%;
        position      : relative;

        .iframe__box {
          position: absolute;
          top     : 0;
          left    : 0;
          right   : 0;
          bottom  : 0;
        }
      }
    }

  }

  .menual__container {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-top    : 160px;

    &>header {
      display        : flex;
      justify-content: center;

      h1 {
        font-style    : normal;
        font-weight   : 700;
        font-size     : 48px;
        line-height   : 64px;
        letter-spacing: -2px;
        color         : #000000;
      }
    }



    .guide__box {
      margin-top     : 24px;
      display        : flex;
      width          : 100%;
      justify-content: center;

      ul {
        width          : 100%;
        display        : flex;
        justify-content: center;
        flex-wrap      : wrap;
        max-width      : 960px;

        li {
          width          : fit-content;
          padding        : 0px 24px;
          margin-right   : 8px;
          background     : #F4F4F4;
          border-radius  : 100px;
          height         : 40px;
          margin-bottom  : 12px;
          display        : flex;
          justify-content: center;
          align-items    : center;
          cursor         : pointer;

          &.on {
            background: $primary-color;

            span {
              color: #fff;
            }
          }

          span {

            font-style    : normal;
            font-weight   : 300;
            font-size     : 14px;
            line-height   : 14px;
            text-align    : center;
            letter-spacing: -1px;
          }
        }
      }
    }

    .img__box {
      margin-top     : 64px;
      display        : flex;
      align-items    : center;
      justify-content: center;
      max-width      : 1280px;
      width          : 100%;
      box-shadow     : rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;

      img {
        width: 100%;
      }
    }

    .button__box {
      display        : flex;
      justify-content: center;
      margin-top     : 48px;

      button {
        display        : flex;
        align-items    : center;
        justify-content: center;
        width          : 480px;
        height         : 48px;
      }
    }
  }


  .download__container {
    display   : flex;
    width     : 100%;
    margin-top: 160px;

    .button__box {
      display: flex;

      button {
        margin-right: 24px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .left__box {
      padding       : 160px 0px;
      background    : $primary-color;
      flex          : 1;
      display       : flex;
      flex-direction: column;
      align-items   : center;

      &>header {
        display       : flex;
        flex-direction: column;
        align-items   : center;

        h1 {
          font-style    : normal;
          font-weight   : 700;
          font-size     : 48px;
          line-height   : 64px;
          letter-spacing: -2px;
          color         : #FFFFFF;
        }

        p {
          margin-top    : 16px;
          font-style    : normal;
          font-weight   : 300;
          font-size     : 24px;
          line-height   : 40px;
          text-align    : center;
          letter-spacing: -1px;
          color         : #FFFFFF;
        }
      }

      button {
        margin-top    : 24px;
        width         : 240px;
        height        : 64px;
        background    : #FFFFFF;
        border-radius : 100px;
        color         : $primary-color;
        font-weight   : 700;
        font-size     : 20px;
        line-height   : 20px;
        text-align    : center;
        letter-spacing: -1px;
      }
    }

    .right__box {
      padding       : 160px 0px;
      flex          : 1;
      background    : #000000;
      flex-direction: column;
      align-items   : center;
      display       : flex;

      &>header {
        display       : flex;
        flex-direction: column;
        align-items   : center;

        h1 {
          font-style    : normal;
          font-weight   : 700;
          font-size     : 48px;
          line-height   : 64px;
          letter-spacing: -2px;
          color         : #FFFFFF;
        }

        p {
          margin-top    : 16px;
          font-style    : normal;
          font-weight   : 300;
          font-size     : 24px;
          line-height   : 40px;
          text-align    : center;
          letter-spacing: -1px;
          color         : #FFFFFF;
        }
      }

      button {
        margin-top    : 24px;
        width         : 240px;
        height        : 64px;
        background    : #FFFFFF;
        border-radius : 100px;
        color         : #000000;
        font-weight   : 700;
        font-size     : 20px;
        line-height   : 20px;
        text-align    : center;
        letter-spacing: -1px;
      }
    }
  }




  // .bg-top-line__box {
  //   display          : flex;
  //   width            : calc(50vw);
  //   height           : 1400px;
  //   background       : url("../images/bg_line_1.png");
  //   position         : absolute;
  //   background-size  : cover;
  //   background-repeat: no-repeat;
  //   top              : -580px;
  //   left             : -260px;
  //   z-index          : 1;
  // }
}


@media only screen and (max-width: 1280px) {
  .home-page {
    .naver-cafe__container {
      margin-top   : 80px;
      margin-bottom: 80px;

      .caption__box {
        h2 {
          font-weight   : 700;
          font-size     : 32px;
          line-height   : 32px;
          text-align    : center;
          letter-spacing: -2px;
        }

        h4 {
          font-style    : normal;
          font-weight   : 300;
          font-size     : 14px;
          line-height   : 24px;
          text-align    : center;
          letter-spacing: -1px;
        }
      }

      .img__box {
        margin-top: 40px;
        width     : calc(100vw - 32px);
        height    : calc((100vw - 32px)*0.7);
      }
    }

    .benefit__container {
      padding-top   : 80px;
      padding-bottom: 80px;

      &>header {
        h1 {
          font-style    : normal;
          font-weight   : 700;
          font-size     : 32px;
          line-height   : 32px;
          letter-spacing: -2px;
        }
      }

      ul {
        display       : flex;
        flex-direction: column;
        grid-gap      : 0px;

        li {
          margin-bottom: 24px;
          padding      : 16px;

          &:last-child {
            margin-bottom: 0px;
          }

          h2 {
            font-style : normal;
            font-weight: 700;
            font-size  : 19px;
            line-height: 20px;
          }

          p {
            font-weight   : 300;
            font-size     : 16px;
            line-height   : 24px;
            letter-spacing: -1px;
            color         : #000000;
          }
        }
      }
    }

    .menual__container {
      margin-top: 80px;

      &>header {
        h1 {
          font-style : normal;
          font-weight: 700;
          font-size  : 24px;
          line-height: 32px;
        }
      }

      .guide__box {
        width: 100%;

        ul {
          width          : 100%;
          flex-wrap      : nowrap;
          overflow-y     : auto;
          max-width      : 100vw;
          justify-content: flex-start;

          li {
            span {
              white-space: nowrap;
            }
          }
        }
      }

      .img__box {
        margin-top: 24px;
        width     : 100%;

        img {
          width: 100%;
        }
      }
    }

    .how-to-use__container {
      margin-top: 80px;

      &>header {
        h1 {
          font-style : normal;
          font-weight: 700;
          font-size  : 24px;
          line-height: 32px;
        }
      }

      .guide__box {
        width: 100%;

        ul {
          width          : 100%;
          flex-wrap      : nowrap;
          overflow-y     : auto;
          max-width      : 100vw;
          justify-content: flex-start;

          li {
            span {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .download__container {
      flex-direction: column;
      margin-top    : 80px;

      .left__box,
      .right__box {
        padding: 80px 0px;

        &>header {
          h1 {
            font-style    : normal;
            font-weight   : 700;
            font-size     : 24px;
            line-height   : 32px;
            letter-spacing: -2px;
          }

          p {
            font-style    : normal;
            font-weight   : 300;
            font-size     : 16px;
            line-height   : 24px;
            text-align    : center;
            letter-spacing: -1px;
          }
        }

        .button__box {
          display       : flex;
          flex-direction: column;

          button {
            font-style    : normal;
            font-weight   : 700;
            font-size     : 20px;
            line-height   : 20px;
            text-align    : center;
            letter-spacing: -1px;
          }
        }
      }
    }

    .bg__item {
      height: 640px;

    }

    .ment__container {
      height: 640px;

      .caption__box {
        h3 {
          font-weight   : 300;
          font-size     : 20px;
          line-height   : 32px;
          text-align    : center;
          letter-spacing: -1px;
        }

        h1 {
          font-style    : normal;
          font-weight   : 500;
          font-size     : 32px;
          line-height   : 48px;
          text-align    : center;
          letter-spacing: -1px;
        }
      }
    }
  }
}