.board-list-page {
  display       : flex;
  flex-direction: column;
  align-items   : center;

  .title__box {
    display        : flex;
    justify-content: flex-start;
    align-items    : center;
    padding-bottom : 24px;
    border-bottom  : solid 1px $placeholder-color;
    height         : 48px;
    margin-top     : 60px;

    h3 {
      font-size: 28px;
    }

    &.between {
      justify-content: space-between;
      width          : 100%;

    }
  }


  .board__box {
    display        : flex;
    justify-content: center;
    margin-top     : 48px;
  }

  .page__box {
    display        : flex;
    justify-content: center;
    margin-top     : 48px;
  }
}

.board-detail-page {
  display       : flex;
  flex-direction: column;
  align-items   : center;

  .board__item {
    margin-top   : 60px;
    box-shadow   : rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;
    border-radius: 24px;
    background   : #fff;
    padding      : 24px;
  }

  .title__box {
    display        : flex;
    justify-content: flex-start;

    h3 {
      font-size: 24px;
    }
  }


  .editor__box {
    display        : flex;
    justify-content: flex-start;
    margin-top     : 24px;
    padding-top    : 24px;
    border-top     : solid 1px $placeholder-color;
  }

  .file__box {
    padding-top    : 24px;
    border-top     : solid 1px $placeholder-color;
    display        : flex;
    margin-top     : 24px;
    justify-content: flex-start;
    flex-direction : column;


    &>header {
      display      : flex;
      margin-bottom: 12px;

      span {
        font-size  : 16px;
        color      : rgb(134, 140, 159);
        font-weight: bold;
      }
    }

    ul {
      display       : flex;
      flex-direction: column;

      li {
        margin-bottom: 4px;

        span {
          color     : $placeholder-color;
          font-size : 16px;
          cursor    : pointer;
          word-break: break-all;

          &:hover {
            color          : $primary-color;
            text-decoration: underline;
          }
        }
      }

    }
  }

  .button__box {
    display        : flex;
    margin-top     : 60px;
    justify-content: center;

    button {
      width: 280px;
    }
  }
}

.board-add-page {
  display       : flex;
  flex-direction: column;
  align-items   : center;

  .title__box {
    margin-top: 60px;

    display        : flex;
    justify-content: flex-start;
    padding-bottom : 24px;
    border-bottom  : solid 1px $placeholder-color;

    h3 {
      font-size: 28px;
    }
  }

  .form__box {
    margin-top    : 48px;
    display       : flex;
    flex-direction: column;

    &>article {
      margin-bottom: 24px;
    }
  }

  .button__box {
    margin-top: 48px;

    display        : flex;
    justify-content: center;

    button {
      width: 360px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .board-list-page {
    .title__box {
      margin-top: 0px;

      h3 {
        font-size: 24px;
      }
    }

    .board__box {
      margin-top: 24px;
    }

    .page__box {}
  }

  .board-detail-page {
    .board__item {
      margin-top: 24px;
    }

    .button__box {
      margin-top: 48px;
    }
  }

  .board-add-page {
    .title__box {
      margin-top: 0px;

      h3 {
        font-size: 24px;
      }
    }
  }
}