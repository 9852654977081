$primary-color       : #FD601C;
$primary-hover-color : rgb(240, 83, 16);
$second-color        : #7bb3ee;
$error-color         : #e50914;
$contrast-color      : #fff;

// content
$text-color       : #111119;
$title-color      : #333;
$placeholder-color: rgb(134, 140, 159);
$border-color     : #eee;
$base-bg-color    : #fff;

//box-shadow
$box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px;
$box-shadow-hover: rgba(56, 72, 157, 0.5) 0px 3px 8px;