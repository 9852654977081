.table-qna {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;
  overflow-y: auto;

  .empty {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 24px;
  }

  .table-header {
    display: flex;
    height: 60px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #dcdcdc;
      box-sizing: border-box;
      background-color: #eeeeee;

      &:last-child {
        border-right: none;
      }

      span {
        font-weight: bold;
        color: #171d30;
      }

      &:first-child {
        min-width: 100px;
      }

      &:nth-child(2) {
        flex: 1;
        min-width: 300px;
      }

      &:nth-child(3) {
        min-width: 160px;
      }

      &:nth-child(4) {
        min-width: 160px;
      }
    }
  }

  .table-data {
    display: flex;
    height: 60px;
    cursor: pointer;

    &:hover {
      // background: red;

      li {
        &:nth-child(2) {
          text-decoration: underline;
        }
      }
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #dcdcdc;
      box-sizing: border-box;
      background: #fff;
      border-bottom: solid 1px #dcdcdc;

      &:last-child {
        border-right: none;
      }

      &:first-child {
        min-width: 100px;
      }

      &:nth-child(2) {
        justify-content: flex-start;
        flex: 1;
        padding: 0px 12px;
        box-sizing: border-box;
        min-width: 300px;
      }

      &:nth-child(3) {
        min-width: 160px;
      }

      &:nth-child(4) {
        min-width: 160px;
      }
    }
  }
}

.table-payment {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;
  overflow-y: auto;

  .empty {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 24px;
  }

  .table-header {
    display: flex;
    height: 60px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #dcdcdc;
      box-sizing: border-box;
      flex: 1;
      background-color: #eeeeee;

      &:last-child {
        border-right: none;
      }

      &:nth-child(1) {
        min-width: 180px;
      }

      &:nth-child(2) {
        min-width: 180px;
      }

      &:nth-child(3) {
        min-width: 180px;
      }

      &:nth-child(4) {
        min-width: 180px;
      }

      &:nth-child(5) {
        min-width: 280px;
      }

      &:nth-child(6) {
        min-width: 180px;
      }

      span {
        font-weight: bold;
        color: #171d30;
      }
    }
  }

  .table-data {
    display: flex;
    height: 60px;
    background: #fff;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #dcdcdc;
      box-sizing: border-box;
      flex: 1;
      border-bottom: solid 1px #dcdcdc;

      &:last-child {
        border-right: none;
      }

      &:nth-child(1) {
        min-width: 180px;
      }

      &:nth-child(2) {
        min-width: 180px;
      }

      &:nth-child(3) {
        min-width: 180px;
      }

      &:nth-child(4) {
        min-width: 180px;
      }

      &:nth-child(5) {
        min-width: 280px;
      }

      &:nth-child(6) {
        min-width: 180px;
      }
    }
  }
}

.table-point {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;
  overflow-y: auto;

  .empty {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 24px;
  }

  .table-header {
    display: flex;
    height: 60px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #dcdcdc;
      box-sizing: border-box;
      flex: 1;
      background-color: #eeeeee;

      &:last-child {
        border-right: none;
      }

      span {
        font-weight: bold;
        color: #171d30;
      }

      &:nth-child(1) {
        min-width: 180px;
      }

      &:nth-child(2) {
        min-width: 180px;
      }

      &:nth-child(3) {
        min-width: 180px;
      }

      &:nth-child(4) {
        min-width: 180px;
      }

      &:nth-child(5) {
        min-width: 180px;
      }

      &:nth-child(6) {
        min-width: 180px;
      }
    }
  }

  .table-data {
    display: flex;
    height: 60px;
    background: #fff;

    &.PaymentEarning,
    &.TransferTo {
      li {
        &:nth-child(2) {
          color: #46bdea;
        }
      }
    }

    &.Calculate,
    &.TransferFrom,
    &.PaymentPoints {
      li {
        &:nth-child(2) {
          color: #e50914;
        }
      }
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #dcdcdc;
      border-bottom: solid 1px #dcdcdc;
      box-sizing: border-box;
      flex: 1;

      &:last-child {
        border-right: none;
      }

      &:nth-child(1) {
        min-width: 180px;
      }

      &:nth-child(2) {
        min-width: 180px;
      }

      &:nth-child(3) {
        min-width: 180px;
      }

      &:nth-child(4) {
        min-width: 180px;
      }

      &:nth-child(5) {
        min-width: 180px;
      }

      &:nth-child(6) {
        min-width: 180px;
      }
    }
  }
}

.table-calculate {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;
  overflow-y: auto;

  .empty {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 24px;
  }

  .table-header {
    display: flex;
    height: 60px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #dcdcdc;
      box-sizing: border-box;
      flex: 1;
      background-color: #eeeeee;

      &:last-child {
        border-right: none;
      }

      span {
        font-weight: bold;
        color: #171d30;
      }

      &:nth-child(1) {
        min-width: 80px;
      }

      &:nth-child(2) {
        min-width: 120px;
      }

      &:nth-child(3) {
        min-width: 140px;
      }

      &:nth-child(4) {
        min-width: 140px;
      }

      &:nth-child(5) {
        min-width: 280px;
      }

      &:nth-child(6) {
        min-width: 100px;
      }

      &:nth-child(7) {
        min-width: 120px;
        padding: 0px 4px;
      }
    }
  }

  .table-data {
    display: flex;
    height: 60px;
    background: #fff;

    &.Complete {
      li {
        &:nth-child(2) {
          color: #46bdea;
        }
      }
    }

    &.Reject {
      li {
        &:nth-child(2) {
          color: #e50914;
        }
      }
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #dcdcdc;
      border-bottom: solid 1px #dcdcdc;
      box-sizing: border-box;
      flex: 1;

      &:last-child {
        border-right: none;
      }

      &:nth-child(1) {
        min-width: 80px;
      }

      &:nth-child(2) {
        min-width: 120px;
      }

      &:nth-child(3) {
        min-width: 140px;
      }

      &:nth-child(4) {
        min-width: 140px;
      }

      &:nth-child(5) {
        min-width: 280px;
      }

      &:nth-child(6) {
        min-width: 100px;
      }

      &:nth-child(7) {
        min-width: 120px;
        padding: 0px 4px;
      }
    }
  }
}
