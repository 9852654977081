.login-layout {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
}

@media only screen and (max-width: 765px) {
  .login-layout {
    .content__container {
      padding: 0px $m-page-padding;
    }
  }
}
