.terms-layout {
  display   : flex;
  width     : 100%;
  min-height: 100vh;
  position  : relative;
  background: $base-bg-color;

  .content__container {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    box-sizing    : border-box;
    width         : 100%;


    &>.content__box {
      width         : 100%;
      max-width     : $wrapper-max-width;
      padding       : 0px $page-padding;
      display       : flex;
      flex-direction: column;
      box-sizing    : border-box;

      .scroll__container {
        display       : flex;
        flex-direction: column;
        width         : 100%;
      }
    }




  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {}

@media only screen and (min-width: 766px) and (max-width: 1024px) {}

@media only screen and (max-width: 1280px) {
  .terms-layout {
    .content__container {
      &>.content__box {
        padding: 0px 16px;
      }
    }

  }
}