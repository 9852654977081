.mypage-info-page {
  display       : flex;
  flex-direction: column;

  .title__box {
    display       : flex;
    border-bottom : solid 1px $placeholder-color;
    padding-bottom: 16px;

    h3 {
      font-size: 24px;
    }
  }

  .input__box {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    width         : 100%;
    margin-top    : 48px;

    .form-input {
      margin-bottom: 24px;
      max-width    : 680px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .referral-viwer {
      display       : flex;
      flex-direction: column;
      align-items   : flex-start;
      margin-bottom : 24px;
      width         : 100%;
      max-width     : 680px;

      .label__box {
        display      : flex;
        margin-bottom: 12px;

        span {
          font-size  : 16px;
          color      : rgb(134, 140, 159);
          font-weight: bold;
        }

        .secession {
          font-size  : 16px;
          color      : 777;
          font-weight: normal;
          cursor     : pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .value__box {
        display    : flex;
        align-items: center;

        span {
          font-size: 16px;
          cursor   : pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .pw__box {
      display       : flex;
      margin-bottom : 24px;
      flex-direction: column;
      width         : 100%;
      max-width     : 680px;
      border-bottom : solid 1px rgba(4, 4, 5, 0.1);

      &>header {
        display      : flex;
        margin-bottom: 12px;

        span {
          font-size  : 16px;
          color      : rgb(134, 140, 159);
          font-weight: bold;
        }
      }

      b {
        cursor   : pointer;
        height   : 48px;
        display  : flex;
        font-size: 16px;
        width    : fit-content;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .button__box {
    margin-top     : 48px;
    display        : flex;
    justify-content: center;
    border-top     : solid 1px $placeholder-color;
    padding-top    : 48px;

    button {
      max-width: 280px;
    }
  }
}

.mypage-qna-list-page {
  display       : flex;
  flex-direction: column;

  .title__box {
    display        : flex;
    border-bottom  : solid 1px $placeholder-color;
    padding-bottom : 16px;
    justify-content: space-between;
    align-items    : center;

    h3 {
      font-size: 24px;
    }

    .button__box {
      display: flex;
    }
  }

  .table__box {
    display        : flex;
    margin-top     : 48px;
    justify-content: center;
  }

  .page__box {
    margin-top     : 48px;
    display        : flex;
    justify-content: center;
  }
}

.mypage-qna-add-page {
  display       : flex;
  flex-direction: column;
  padding-bottom: 120px;

  .title__box {
    display        : flex;
    border-bottom  : solid 1px $placeholder-color;
    padding-bottom : 16px;
    justify-content: space-between;

    h3 {
      font-size: 24px;
    }

    .button__box {
      display: flex;
    }
  }

  .form__box {
    margin-top    : 48px;
    display       : flex;
    flex-direction: column;

    &>article {
      margin-bottom: 24px;
    }
  }

  .button__box {
    margin-top: 48px;

    display        : flex;
    justify-content: center;

    button {
      width: 360px;
    }
  }
}

.mypage-qna-detail-page {
  display       : flex;
  flex-direction: column;
  padding-bottom: 120px;

  .title__box {
    display        : flex;
    border-bottom  : solid 1px $placeholder-color;
    padding-bottom : 16px;
    justify-content: space-between;

    h3 {
      font-size: 24px;
    }

    .button__box {
      display: flex;
    }
  }

  .form__box {
    margin-top    : 48px;
    display       : flex;
    flex-direction: column;

    &>article {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &.answer {
      padding-top: 24px;
      border-top : solid 1px $placeholder-color;

      .answer_wait__box {
        display       : flex;
        flex-direction: column;

        .label__box {
          display      : flex;
          margin-bottom: 12px;

          span {
            font-size  : 16px;
            color      : rgb(134, 140, 159);
            font-weight: bold;
          }
        }

        .text {
          display         : flex;
          align-items     : center;
          height          : 48px;
          background      : transparent;
          padding         : 0px 16px;
          box-sizing      : border-box;
          color           : $text-color;
          font-size       : 16px;
          border          : solid 1px rgba(4, 4, 5, 0.1);
          background-color: #fff;
          height          : 48px;
          width           : 100%;
          border-radius   : 8px;
          color           : rgb(134, 140, 159);
        }
      }
    }
  }

  .file__box {
    display        : flex;
    justify-content: flex-start;
    flex-direction : column;

    &>header {
      display      : flex;
      margin-bottom: 12px;

      span {
        font-size  : 16px;
        color      : rgb(134, 140, 159);
        font-weight: bold;
      }
    }

    ul {
      display       : flex;
      flex-direction: column;

      li {
        margin-bottom: 4px;

        span {
          color     : $placeholder-color;
          font-size : 16px;
          cursor    : pointer;
          word-break: break-all;

          &:hover {
            color          : $primary-color;
            text-decoration: underline;
          }
        }
      }
    }

    .empty {
      display: flex;
      height : 48px;

      span {
        color    : $placeholder-color;
        font-size: 16px;
      }
    }
  }

  .button__box {
    margin-top: 48px;

    display        : flex;
    justify-content: center;

    button {
      width: 360px;
    }
  }
}

.mypage-membership-page {
  display       : flex;
  flex-direction: column;
  padding-bottom: 120px;

  .title__box {
    display        : flex;
    border-bottom  : solid 1px $placeholder-color;
    padding-bottom : 16px;
    justify-content: space-between;

    h3 {
      font-size: 24px;
    }

    .button__box {
      display: flex;
    }
  }
}

.mypage-membership-page {
  display       : flex;
  flex-direction: column;
  padding-bottom: 120px;

  .title__box {
    display        : flex;
    border-bottom  : solid 1px $placeholder-color;
    padding-bottom : 16px;
    justify-content: space-between;

    h3 {
      font-size: 24px;
    }

    .button__box {
      display: flex;
    }
  }

  .membership-status__box {
    display      : flex;
    margin-bottom: 64px;

    ul {
      display       : flex;
      flex-direction: column;
      padding-top   : 24px;

      li {
        margin-bottom: 24px;
        display      : flex;
        align-content: center;

        &>header {
          margin-right: 8px;

          span {
            font-size: 18px;
            color    : $placeholder-color;
          }
        }

        .value__box {
          display: flex;

          span {
            font-size  : 18px;
            font-weight: bold;
            color      : $primary-color;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .table__box {
    display        : flex;
    margin-top     : 48px;
    justify-content: center;
  }

  .page__box {
    margin-top     : 48px;
    display        : flex;
    justify-content: center;
  }
}

.mypage-point-page {
  display       : flex;
  flex-direction: column;
  padding-bottom: 120px;

  .title__box {
    display        : flex;
    border-bottom  : solid 1px $placeholder-color;
    padding-bottom : 16px;
    justify-content: space-between;
    align-items    : center;

    button {
      width: 240px;
    }

    b {
      font-size  : 24px;
      font-weight: bold;
      color      : $primary-color;
    }

    h3 {
      font-size: 24px;
    }

    .button__box {
      display: flex;
    }
  }

  .banner__box {
    display         : flex;
    height          : 60px;
    // background   : #46bdea;
    background-image: linear-gradient(to right, #61b2f8 0%, #42eff8 100%);
    margin-top      : 24px;
    align-items     : center;
    padding         : 0px 24px;
    border-radius   : 100px;

    &>header {
      display     : flex;
      margin-right: 8px;

      span {
        color: #f7f7f7;
      }
    }

    .value__box {
      display: flex;

      span {
        font-size  : 16px;
        color      : #f7f7f7;
        font-weight: bold;
      }
    }
  }

  .point-status__box {
    display      : flex;
    margin-bottom: 64px;
  }

  .table__box {
    display        : flex;
    margin-top     : 48px;
    justify-content: center;
  }

  .page__box {
    margin-top     : 48px;
    display        : flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 1280px) {
  .mypage-membership-page {
    padding-bottom: 0px;

    .membership-status__box {
      margin-bottom: 48px;
    }
  }

  .mypage-qna-add-page {
    padding-bottom: 0px;
  }

  .mypage-qna-list-page {
    .title__box {
      button {
        width: 120px;

        span {
          font-size: 14px;
        }
      }
    }
  }

  .mypage-point-page {
    padding-bottom: 0px;

    &.calculate {
      .title__box {
        button {
          width: 160px;
        }
      }
    }

    .title__box {
      flex-direction: column;
      align-items   : flex-start;

      h3 {
        font-size: 18px;
      }

      button {
        margin-top: 16px;
        width     : 130px;

        span {
          font-size: 14px;
        }
      }
    }

    .banner__box {
      flex-direction: column;
      height        : fit-content;
      padding       : 16px;
      box-sizing    : border-box;
      border-radius : 8px;

      &>header {
        margin-right : 0px;
        margin-bottom: 4px;
      }

      .value__box {
        span {
          font-size : 14px;
          text-align: center;
        }
      }
    }

    .table__box {
      margin-top: 24px;
    }
  }
}