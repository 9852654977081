.mypage-layout {
  display   : flex;
  width     : 100%;
  min-height: 100vh;
  position  : relative;
  background: $base-bg-color;

  .content__container {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    box-sizing    : border-box;
    width         : 100%;

    &>.header__box {
      z-index        : 30;
      display        : flex;
      height         : $header-height;
      position       : fixed;
      top            : 0;
      right          : 0;
      width          : 100%;
      box-shadow     : 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
      background     : rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(13px);
      justify-content: center;
    }

    &>.footer__box {
      display         : flex;
      height          : fit-content;
      padding-top     : 24px;
      padding-bottom  : 48px;
      width           : 100%;
      background      : rgba(255, 255, 255, 0.7);
      backdrop-filter : blur(13px);
      justify-content : center;
      background-color: #111;
    }

    &>.content__box {
      width         : 100%;
      margin-top    : 100px;
      display       : flex;
      max-width     : $wrapper-max-width;
      padding       : 0px $page-padding;
      display       : flex;
      box-sizing    : border-box;
      padding-top   : 60px;
      padding-bottom: 80px;
      min-height    : calc(100vh - 96px - 200px);

      .nav__container {
        display     : flex;
        width       : 280px;
        margin-right: 60px;
      }

      .scroll__container {
        flex          : 1;
        display       : flex;
        flex-direction: column;
        width         : 100%;
      }
    }

    .content__wrapper {
      display       : flex;
      max-width     : $wrapper-max-width;
      padding       : 0px $page-padding;
      width         : 100%;
      box-sizing    : border-box;
      flex-direction: column;
    }

    .full__wrapper {
      display       : flex;
      width         : 100%;
      flex-direction: column;
      align-items   : flex-start;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .mypage-layout {
    .content__container {
      &>.content__box {
        padding       : 0px;
        padding-bottom: $m-page-padding;
        margin-top    : 48px;
        min-height    : calc(100vh - 48px - 242px);
        flex-direction: column;
        margin-bottom : 48px;

        .nav__container {
          width       : 100%;
          margin-right: 0px;
        }

        .scroll__container {
          margin-top: 24px;
          padding   : 0px 16px;
          box-sizing: border-box;
        }
      }

      .content__wrapper {
        padding: 0px 16px;
      }

      &>.header__box {
        height: $m-header-height;
      }


    }

  }
}