.terms-page {
  display       : flex;
  flex-direction: column;
  padding-bottom: 80px;

  .logo__box {
    margin-top     : 60px;
    display        : flex;
    justify-content: center;
    margin-bottom  : 24px;
  }

  .title__box {
    display       : flex;
    margin-bottom : 24px;
    padding-bottom: 24px;
    border-bottom : solid 1px $placeholder-color;

    h1 {
      color    : $placeholder-color;
      font-size: 24px;
    }
  }

  .content__box {
    display  : flex;
    color    : $placeholder-color;
    font-size: 12px;

    &>div {
      white-space: break-spaces;
    }
  }
}