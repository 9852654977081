.custom-paging {

  ul {
    li {


      button {
        margin       : 0px;
        border-radius: 0px;
        border-right : none;
        border-color : transparent;
        color        : #999;
        border-radius: 8px;
        overflow     : hidden;

        &.Mui-selected {
          color       : #fff;
          background  : $primary-color;
          border      : solid 1px #fcfcfc;
          border-right: none;
        }

        &.Mui-disabled {
          opacity: 0.75;
        }
      }

      &:first-child {
        button {}
      }

      &:last-child {
        button {
          border-right: solid 1px transparent;
        }
      }

      .MuiPaginationItem-ellipsis {
        border       : solid 1px transparent;
        width        : 32px;
        height       : 32px;
        border-radius: 0px;
        margin       : 0px;
        border-right : none;
        padding-top  : 1px;
        color        : $text-color;
      }
    }
  }
}