.modal-layer {
  position           : absolute;
  top                : 50%;
  left               : 50%;
  transform          : translate(-50%, -50%);
  width              : 480px;
  background         : #fff;
  border-radius      : 0px;
  overflow           : hidden;
  // border          : solid 1px $placeholder-color;
  background-color   : #efefef;
  box-shadow         : 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius      : 24px;
  overflow           : hidden;


  &.auto {
    width : auto;
    border: none;
  }

  .header__box {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-bottom  : 24px;
    padding-top    : 24px;


    h4 {
      font-size: 24px;
    }
  }

  .content__box {
    display       : flex;
    flex-direction: column;
    overflow-y    : auto;
    flex          : 1;
    padding       : 0px 24px;
  }
}

.modal-delete {
  display       : flex;
  flex-direction: column;


  .button__box {
    display   : flex;
    margin-top: 24px;

    button {
      flex         : 1;
      border-radius: 0px;
    }
  }

  .header__box {
    h4 {
      font-size: 24px;
      color    : #171717;
    }
  }

  .content__box {
    span {
      color    : #757182;
      font-size: 16px;
    }
  }
}

.modal-confirm {
  display       : flex;
  flex-direction: column;


  .button__box {
    display   : flex;
    margin-top: 24px;

    button {
      flex         : 1;
      border-radius: 0px;
    }
  }

  .header__box {
    h4 {
      font-size: 24px;
      color    : #171717;
    }
  }

  .content__box {
    span {
      color    : #757182;
      font-size: 16px;
    }
  }
}


.modal-point {
  display       : flex;
  flex-direction: column;


  .button__box {
    display   : flex;
    margin-top: 24px;

    button {
      flex         : 1;
      border-radius: 0px;
    }
  }

  .header__box {
    h4 {
      font-size: 24px;
      color    : #171717;
    }
  }

  .form__box {
    display       : flex;
    flex-direction: column;
    padding       : 0px 24px;
    margin-top    : 24px;

    &>article {
      margin-bottom: 12px;
    }

  }

  .content__box {
    span {
      color    : #757182;
      font-size: 16px;
    }
  }

  .guide-text__box {
    display       : flex;
    flex-direction: column;
    box-sizing    : border-box;
    padding       : 0px 24px;

    span {
      font-size: 14px;
      color    : #777;
    }
  }

  .member-info__box {
    display       : flex;
    flex-direction: column;
    margin-bottom : 16px;
    align-items   : center;

    ul {
      display: flex;

      li {
        display     : flex;
        align-items : center;
        margin-right: 24px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          font-size: 16px;
          color    : #777;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .modal-layer {
    width     : calc(100vw - 32px);
    max-height: calc(100vh - 32px);
    overflow-y: auto;
  }
}