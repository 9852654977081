.lang-selector {
  display    : flex;
  align-items: center;
  width      : fit-content;
  cursor     : pointer;
  position   : relative;
  padding    : 0px;

  .img__box {
    display        : flex;
    width          : 32px;
    height         : 16px;
    margin-right   : 6px;
    justify-content: center;
    align-items    : center;
    border         : solid 1px #e8e8e8;

    img {
      width   : 32px;
      height  : 16px;
      overflow: hidden;
    }
  }

  .label {
    font-size: 12px;
    color    : #888;
  }


  .open__box {
    border       : solid 1px #efefef;
    border-radius: 8px;
    background   : #fff;

    &.on {
      display: flex;
    }

    display : none;
    width   : fit-content;
    position: absolute;
    top     : 24px;
    left    : -15px;
    padding : 0px 12px;

    ul {
      display       : flex;
      flex-direction: column;
      padding       : 12px 0px;

      li {
        display      : flex;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0px;
        }

        .img__box {
          display        : flex;
          width          : 32px;
          height         : 16px;
          margin-right   : 6px;
          justify-content: center;
          align-items    : center;
          border         : solid 1px #e8e8e8;

          img {
            width   : 32px;
            height  : 16px;
            overflow: hidden;
          }
        }

        span {
          font-size: 13px;
          color    : #999;

          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}