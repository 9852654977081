.form-input {
  display       : flex;
  width         : 100%;
  border-radius : 8px;
  flex-direction: column;
  align-items   : flex-start;

  .label__box {
    display      : flex;
    margin-bottom: 12px;

    span {
      font-size  : 16px;
      color      : rgb(134, 140, 159);
      font-weight: bold;
    }
  }

  input {
    height    : 100%;
    background: transparent;
    padding   : 0px 16px;
    box-sizing: border-box;
    color     : $text-color;
    font-size : 16px;
    border    : solid 1px rgba(4, 4, 5, 0.1);

    background-color: #fff;
    height          : 48px;
    width           : 100%;
    border-radius   : 8px;

    &::placeholder {
      color    : $placeholder-color;
      font-size: 14px;
    }

    &:focus {
      border-color: $primary-color;
    }
  }
}

.form-toast-editor {
  display       : flex;
  flex-direction: column;

  .label__box {
    display      : flex;
    margin-bottom: 12px;

    span {
      font-size  : 16px;
      color      : rgb(134, 140, 159);
      font-weight: bold;
    }
  }

  .editor__box {
    width: 100%;
  }
}

.form-toast-viewer {
  display       : flex;
  flex-direction: column;

  &.readOnly {
    &.content {
      .editor__box {
        background: #f4f4f4;
      }
    }
  }

  &>header {
    display      : flex;
    align-items  : center;
    margin-bottom: 14px;

    span {
      font-size     : 14px;
      font-weight   : normal;
      font-stretch  : normal;
      font-style    : normal;
      line-height   : normal;
      letter-spacing: normal;
    }
  }

  &.content {
    .editor__box {
      // background-color: #f4f4f4;
      background   : transparent;
      border-radius: 8px;
      padding      : 16px;
      box-sizing   : border-box;
      min-height   : 560px;
    }
  }

  .editor__box {
    width: 100%;
  }
}

.form-file-multiple-uploader {
  display       : flex;
  flex-direction: column;

  .label__box {
    display: flex;

    span {
      font-size  : 16px;
      color      : rgb(134, 140, 159);
      font-weight: bold;
    }
  }

  .grid__box {
    display       : flex;
    flex-direction: column;
    margin-top    : 24px;

    &.image {
      li {}
    }

    &.file {
      li {}
    }

    li {
      display        : flex;
      justify-content: center;
      align-items    : center;
      width          : 100%;
      position       : relative;
      overflow       : hidden;

      &.add {
        cursor          : pointer;
        display         : flex;
        height          : 40px;
        border          : solid 1px rgba(4, 4, 5, 0.1);
        background-color: #fff;
        border-radius   : 8px;
        overflow        : hidden;

        svg {}

        &:hover {
          border-color    : $primary-color;
          background-color: #fff;
          color           : $primary-color;

          .add-button {
            color: $primary-color;
          }
        }
      }

      .img__box {
        display   : flex;
        width     : 100%;
        max-width : 260px;
        overflow  : hidden;
        padding   : $content-padding;
        position  : relative;
        box-sizing: border-box;

        img {
          cursor: pointer;
          width : 100%;
        }
      }

      .file__box {
        margin-bottom   : 24px;
        border          : solid 1px rgba(4, 4, 5, 0.1);
        background-color: #fff;
        border-radius   : 8px;
        height          : 40px;
        align-items     : center;
        padding         : 0px 12px;
        display         : flex;
        width           : 100%;
        box-sizing      : border-box;
        position        : relative;

        span {
          word-break: break-all;
          cursor    : pointer;
          color     : $placeholder-color;
          word-break: break-all;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .close__box {
        position      : absolute;
        display       : flex;
        flex-direction: column;
        align-items   : flex-end;
        right         : 12px;
        top           : 8px;
        z-index       : 10;
        color         : $placeholder-color;

        &>div {
          cursor: pointer;
        }

        &:hover {
          color: $primary-color;
        }

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .input__box {
    display: none;
  }
}

.form-area {
  display       : flex;
  width         : 100%;
  border-radius : 8px;
  flex-direction: column;
  align-items   : flex-start;

  .label__box {
    display      : flex;
    margin-bottom: 12px;

    span {
      font-size  : 16px;
      color      : rgb(134, 140, 159);
      font-weight: bold;
    }
  }

  textarea {
    height          : 100%;
    background      : transparent;
    padding         : 16px;
    box-sizing      : border-box;
    color           : $text-color;
    font-size       : 16px;
    border          : solid 1px rgba(4, 4, 5, 0.1);
    background-color: #fff;
    height          : 480px;
    width           : 100%;
    border-radius   : 8px;

    &::placeholder {
      color    : $placeholder-color;
      font-size: 14px;
    }

    &:focus {
      border-color: $primary-color;
    }
  }
}