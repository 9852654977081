.gradient-text {
  background             : linear-gradient(93.26deg, #fc7373 2.74%, #ff9900 38.2%, #fc78ff 69.61%, #7fc5f8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width                  : fit-content;
}

.card {
  border-radius: 16px;
  background   : #fff;
  border       : solid 1px rgba(4, 4, 5, 0.1);
  padding      : $content-padding;
  box-sizing   : border-box;
}