.main-header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;

  .mobile {
    display: none;
  }

  .up {
    display: flex;
    width: 100%;
    height: 36px;
    border-bottom: solid 1px #e5e5e5;
    justify-content: center;
  }

  .down {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 1;
  }

  .header__wrapper {
    display: flex;
    width: 100%;
    max-width: $wrapper-max-width;
    justify-content: space-between;
    padding: 0px $page-padding;
    box-sizing: border-box;
  }

  .lang__box {
    display: flex;
    align-items: center;
  }

  .outlink__box {
    display: flex;

    ul {
      display: flex;
      align-items: center;

      li {
        display: flex;
        margin-right: 16px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          cursor: pointer;
          font-size: 13px;
        }
      }
    }
  }

  .title__box {
    display: flex;
    align-items: center;

    h1 {
    }
  }

  .option__box {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ul {
      display: flex;
      margin-right: 32px;

      li {
        margin-right: 32px;

        &.on {
          span {
            color: $primary-color;
            font-weight: bold;
          }
        }

        span {
          cursor: pointer;
          font-size: 16px;
          font-weight: normal;
          color: #131313;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .button__box {
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1280px) {
}

@media only screen and (max-width: 1280px) {
  .main-header {
    .up {
      display: none;
    }

    .down {
      display: none;
    }

    .mobile {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      align-items: center;
      padding: 0px 16px;
      box-sizing: border-box;

      .logo__box {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .logo {
          height: 50px;
          width: 66px;
        }
      }

      .lang__box {
        width: 100px;
        display: flex;
        justify-content: flex-start;
        height: 100%;
        align-items: center;
      }

      .menu__box {
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  .modal-menu {
    display: flex;
    width: 100%;
    height: -webkit-fill-available;
    overflow: hidden;
    background: #fff;
    flex-direction: column;
    align-items: center;

    & > header {
      display: flex;
      padding: 0px 16px;
      height: 48px;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      box-sizing: border-box;
    }

    .nav-list__box {
      display: flex;
      flex: 1;
      overflow-y: auto;
      justify-content: center;
      align-items: center;

      ul {
        display: flex;
        flex-direction: column;

        li {
          margin-bottom: 32px;
          display: flex;
          justify-content: center;

          &.on {
            span {
              color: $primary-color;
            }
          }

          span {
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -1px;
            color: #000000;
          }
        }
      }
    }

    .button__box {
      display: flex;
      margin-bottom: 48px;
    }
  }
}
