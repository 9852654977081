@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");


* {
  font-family: 'Poppins', 'Pretendard', sans-serif;
  line-height: normal;
}

html {
  font-size: 16px;
}

h1 {
  font-size: 1.4rem;
}

h2 {
  font-size: 1.3rem;
}

h3 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1.1rem;
}

span,
b {
  font-size: 1rem;
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {}

@media only screen and (min-width: 766px) and (max-width: 1024px) {}

@media only screen and (max-width: 765px) {}