.downloads-page {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  height        : 100%;

  .downloads__container {
    display        : flex;
    flex-direction : column;
    max-width      : 1600px;
    width          : 100%;
    padding        : 40px 40px;
    box-sizing     : border-box;
    justify-content: center;

    ul {
      display              : grid;
      grid-template-columns: repeat(2, 1fr);
      width                : 100%;
      grid-gap             : 24px;

      li {
        display         : flex;
        align-items     : center;
        padding         : 24px;
        box-sizing      : border-box;
        border-radius   : 16px;
        cursor          : pointer;
        background-image: linear-gradient(149deg, #f6d365 0%, #FD601C 100%);

        &.closed {
          cursor: not-allowed;
        }

        .title__box {
          display: flex;

          span {
            font-size  : 28px;
            font-weight: 500;
            color      : #fff;
          }
        }

        .desc__box {
          font-size: 16px;
          color    : rgba(255, 255, 255, 0.7);
        }

        .icon__box {
          display        : flex;
          flex-direction : row;
          justify-content: flex-end;
          margin-right   : 8px;

          img {
            width : 120px;
            height: 120px;
          }
        }



      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .downloads-page {
    .downloads__container {
      width  : 100%;
      padding: 24px 16px;

      ul {
        grid-template-columns: repeat(1, 1fr);
        grid-gap             : 24px;

        li {
          .title__box {

            span {
              font-size: 16px;
            }
          }

          .desc__box {
            span {
              font-size: 14px;
            }
          }

          .icon__box {
            img {
              width : 60px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}