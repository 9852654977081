.home-banner {
  display       : flex;
  width         : 100%;
  flex-direction: column;
  align-items   : center;
  z-index       : 10;

  .banner__wrapper {
    display          : flex;
    max-width        : $wrapper-max-width;
    // padding       : 60px $page-padding;
    width            : 100%;
    box-sizing       : border-box;
    flex-direction   : row;
    min-height       : 600px;
    padding-top      : 120px;
    flex-direction   : column;
    align-items      : center;
  }

  .text__box {
    display       : flex;
    flex-direction: column;
    width         : fit-content;
    align-items   : center;

    h1 {
      font-style    : normal;
      font-weight   : 700;
      font-size     : 64px;
      line-height   : 80px;
      text-align    : center;
      letter-spacing: -2px;
      color         : #000000;
      max-width     : 850px;
    }

    p {
      margin-top    : 32px;
      text-align    : center;
      font-style    : normal;
      font-weight   : 300;
      font-size     : 18px;
      line-height   : 28px;
      text-align    : center;
      letter-spacing: -1px;
      color         : #000000;
      white-space   : pre-line;
    }

    h4 {
      margin-top    : 32px;
      font-style    : normal;
      font-weight   : 700;
      font-size     : 28px;
      line-height   : 34px;
      text-align    : center;
      letter-spacing: -2px;
      color         : #000000;
    }
  }

  .chart__box {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-top    : 80px;
    width         : 100%;
    max-width     : 1200px;
    height        : 680px;
    position      : relative;


    .recharts-responsive-container {

      background   : #ffffff;
      box-shadow   : rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;
      border-radius: 24px;
    }

    &>header {
      display        : flex;
      justify-content: flex-end;
      margin-bottom  : 24px;

      ul {
        display: flex;

        li {
          height          : 40px;
          padding         : 0px 12px;
          display         : flex;
          justify-content : center;
          align-items     : center;
          background-color: #F4F4F4;
          width           : 120px;
          overflow        : hidden;
          cursor          : pointer;
          border-right    : solid 1px #E6E6E6;

          &:first-child {
            border-top-left-radius   : 100px;
            border-bottom-left-radius: 100px;
          }

          &:last-child {
            border-top-right-radius   : 100px;
            border-bottom-right-radius: 100px;
          }

          span {
            color: #999999;
          }

          &.on {
            background: $primary-color;

            span {
              color: #fff;
            }
          }
        }

      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .home-banner {
    .banner__wrapper {
      padding    : 0px 16px;
      padding-top: 40px;

      .text__box {
        h1 {
          font-style    : normal;
          font-weight   : 700;
          font-size     : 32px;
          line-height   : 48px;
          text-align    : center;
          letter-spacing: -2px;
        }

        p {
          margin-top : 24px;
          font-weight: 300;
          font-size  : 16px;
          line-height: 24px;
          /* or 150% */

          text-align    : center;
          letter-spacing: -1px;
        }


        h4 {
          font-size  : 24px;
          line-height: 32px;
        }
      }

      .chart__box {
        margin-top: 40px;

        &>header {
          width: 100%;

          ul {
            width: 100%;

            li {
              flex : 1;
              width: 100%;
            }
          }
        }
      }
    }
  }
}