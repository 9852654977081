.ico {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: transparent;
  cursor: pointer;

  &.logo {
    background-image: url("./images/quant_king_logo.png");
    width: 80px;
    height: 60px;
    background-position: center;
    background-size: cover;

    &.big {
      width: 133px;
      height: 100px;
    }
  }

  &.menu {
    background-image: url("./images/m_menu.png");
    width: 24px;
    height: 12px;
    background-position: center;
    background-size: cover;
  }

  &.x_mark {
    background-image: url("./images/x_mark.png");
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
}

@media only screen and (max-width: 765px) {
  .ico {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
