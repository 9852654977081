.board {
  display       : flex;
  width         : 100%;
  flex-direction: column;
  border-radius : 8px;
  box-shadow    : rgba(0, 0, 0, 0.14) 1px 2px 4px 2px;
  overflow-y    : auto;

  .empty {
    display     : flex;
    align-items : center;
    height      : 60px;
    padding-left: 24px;

    span {
      color: #777;
    }
  }

  &.free {
    .board-header {
      li {
        &:nth-child(3) {
          min-width: 220px;
        }

        &:nth-child(4) {
          min-width: 160px;
        }

        &:nth-child(5) {
          min-width: 160px;
        }
      }
    }

    .board-data {
      li {
        &:nth-child(3) {
          min-width: 220px;
        }

        &:nth-child(4) {
          min-width: 160px;
        }

        &:nth-child(5) {
          min-width: 160px;
        }
      }

    }
  }

  .board-header {
    display: flex;
    height : 60px;

    li {
      display         : flex;
      justify-content : center;
      align-items     : center;
      border-right    : solid 1px #dcdcdc;
      box-sizing      : border-box;
      background-color: #eeeeee;

      span {
        font-weight: bold;
        color      : #171d30;
      }

      &:last-child {
        border-right: none;
      }

      &:first-child {
        min-width: 100px;
      }

      &:nth-child(2) {
        flex     : 1;
        min-width: 300px;
      }

      &:nth-child(3) {
        min-width: 160px;
      }

      &:nth-child(4) {
        min-width: 160px;
      }
    }
  }

  .board-data {
    display   : flex;
    height    : 60px;
    cursor    : pointer;
    background: #fff;


    &:hover {
      // background: red;

      li {
        &:nth-child(2) {
          text-decoration: underline;
        }
      }
    }

    li {
      display        : flex;
      justify-content: center;
      align-items    : center;
      border-right   : solid 1px #dcdcdc;
      box-sizing     : border-box;
      border-bottom  : solid 1px #dcdcdc;

      &:last-child {
        border-right: none;
      }

      &:first-child {
        min-width: 100px;

      }

      &:nth-child(2) {
        justify-content: flex-start;
        flex           : 1;
        padding        : 0px 12px;
        box-sizing     : border-box;
        min-width      : 300px;

      }

      &:nth-child(3) {
        min-width: 160px;
      }

      &:nth-child(4) {
        min-width: 160px;
      }
    }
  }
}