.banner-chart__legend {
  display        : flex;
  width          : 100%;
  margin-top     : 16px;
  margin-bottom  : 24px;
  justify-content: center;

  li {
    display     : flex;
    align-items : center;
    margin-right: 8px;

    .circle {
      width        : 8px;
      height       : 8px;
      border-radius: 50%;
      margin-right : 4px;

    }
  }
}

@media only screen and (max-width: 1280px) {
  .banner-chart__legend {
    display              : grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom        : 48px;

    li {
      justify-content: center;
    }
  }

}